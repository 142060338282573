import React from "react"
import Layout from "../components/core/Layout"
import SEO from "../components/core/Seo"
import Hero from "../components/happenings/Hero"
import NavBlock from "../components/happenings/HeroNav"
import IntroBlock from "../components/happenings/Intro"
import CarouselBlock from "../components/happenings/CarouselBlock"
import Pagination from "../components/happenings/Pagination"

const category = props => {
  const path = props.location.pathName
  const blogData = props.data.blog
  const categories = props.data.categories.nodes
  const { splashBrush, splashBrushMobile, articles, featuredArticles } = props.data
  const { currentPage, totalPages , slug } = props.pageContext;

  return (
    <Layout>
      <SEO
        title={blogData.metaTitle}
        description={blogData.metaDescription}
        fullTitle={false}
        path={path}
      />
      <Hero intro={blogData.intro} />
      <NavBlock
        splashBrush={splashBrush}
        splashBrushMobile={splashBrushMobile}
        categories={categories}
      />
      {articles && <IntroBlock articles={articles.edges} />}
      <Pagination currentPage={currentPage} total={totalPages} slug={slug}/>
      <CarouselBlock
        carouselTitle={featuredArticles.nodes[0].title}
        slides={featuredArticles.nodes[0].articles}
      />
    </Layout>
  )
}

export default category

export const pageQuery = graphql`
  query CatrgoryPageQuery($slug: String!, $slugs: [String!]!) {
    blog: contentfulPage(slug: { eq: $slug }) {
      metaTitle
      metaDescription
      slug
      intro {
        subTitle
        title
        hero {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    categories: allContentfulCategory {
      nodes {
        category
      }
    }
    articles: allContentfulArticle(
      sort: { order: DESC, fields: date }
      filter: { slug: { in: $slugs } }
    ) {
      edges {
        node {
          title
          date(formatString: "MMM DD, YYYY")
          intro {
            json
          }
          category {
            category
          }
          slug
          thumbnail {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    featuredArticles: allContentfulFeaturedArticles {
      nodes {
        title
        articles {
          title
          date(formatString: "MMM DD, YYYY")
          slug
          thumbnail {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    splashBrush: file(relativePath: { eq: "happenings/splash-brush-top.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    splashBrushMobile: file(
      relativePath: { eq: "happenings/splash-brush-top-mobile.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
