import React from 'react';
import styled from 'styled-components';


const OverlayStyled = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    min-width: 100%;
    min-height: 100%;
    background-color: transparent;
`;
const BottomOverlay = styled.div`
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 400px;
    background-image: linear-gradient(to bottom, rgba(2, 9, 19, 0.0), rgba(2, 9, 19, 1));
`;

const OverlayBottom = () => {
    return (<OverlayStyled>
        <BottomOverlay />
    </OverlayStyled>);
}

export default OverlayBottom;
