import React from "react";
import styled from "styled-components";
import { Container } from "styled-bootstrap-grid";
import NewsEntry from "./NewsEntry";

const IntroBackground = styled.div`
    width: 100%;
    background: transparent;
    padding-left: 0;
    padding-right: 0;

`;

const ContentContainer = styled(Container)`
    padding: 68px 0 0 0;
    @media(min-width: 768px){
        padding-top: 151px;
        padding-left: 0;
        padding-right: 0;
    }
`;

const IntroBlock = ({articles}) => {
  return (
    <IntroBackground>
      <ContentContainer>
        {articles.map(({node: article}, index) => {
          return (
            <NewsEntry
            key={index}
            imgSrc={article.thumbnail.fluid}
            date={article.date}
            title={article.title}
            quote={article.intro}
            reverse={index % 2 === 0}
            link={article.slug}/>
          )
        })}
      </ContentContainer>
    </IntroBackground>
  )
}


export default IntroBlock

