import React, { useState, useRef } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import { Row } from "styled-bootstrap-grid"
import LeftArrowImg from "../../resources/images/happenings/black-arrow-left.svg"
import RightArrowImg from "../../resources/images/happenings/black-arrow-right.svg"
import Slider from "react-slick/lib"
import { Link } from "gatsby"

const StyledRow = styled(Row)`
  margin-left: 0;
  margin-right: 0;
  padding-left: 33px;
  padding-right: 33px;
  @media (min-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const Previous = styled.button`
  cursor: pointer;
  border: none;
  width: 40px;
  padding: 0 0 8px 0;
  margin-right: 0px;
  background: transparent;
  display: none;
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
  @media (min-width: 992px) {
    display: block;
  }
`

const Next = styled.button`
  cursor: pointer;
  border: none;
  width: 40px;
  padding: 0 0 8px 0;
  margin-left: 0px;
  background: transparent;
  display: none;
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
  @media (min-width: 992px) {
    display: block;
  }
`
const MobilePrevious = styled.button`
  cursor: pointer;
  border: none;
  width: 40px;
  padding: 0 0 8px 0;
  margin-right: 0px;
  background: transparent;
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
  @media (min-width: 992px) {
    display: none;
  }
`

const MobileNext = styled.button`
  cursor: pointer;
  border: none;
  width: 40px;
  padding: 0 0 8px 0;
  margin-left: 0px;
  background: transparent;
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
  @media (min-width: 992px) {
    display: none;
  }
`

const ArrowLeft = styled.img``

const ArrowRight = styled.img``

const Pagination = styled.div`
  margin-bottom: 103px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const PaginationNumber = styled(Link)`
  ${fonts.swissBlackExtended};
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 19px;
  border: none;
  padding: 0 10px;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #222e3c;
  margin-top: 0;
  background: transparent;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ active }) => active && "color: #222e3c;background: transparent;"};
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
    background: transparent;
  }
  @media (min-width: 768px) {
    padding: 19px;
  }
`

const StyledSlider = styled(Slider)`
  width: 260px;
  .slick-track {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slick-slide {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }
  @media (min-width: 768px) {
    width: 400px;
  }
`

const PaginationBlock = ({ currentPage, total, slug }) => {
  const [slideIndex, setSlideIndex] = useState(currentPage - 1)
  const slideRef = useRef()

  const pageNumbers = []

  for (let i = 1; i <= total; i++) {
    pageNumbers.push(i)
  }

  const changeSlide = diff => {
    const index = slideIndex + diff

    slideRef.current.slickGoTo(index)
  }

  const getPath = (slug, number) => {
    if (slug === "all") {
      return number === 1 ? "/happenings" : `/happenings/${number}`
    } else {
      return number === 1
        ? `/happenings/${slug}`
        : `/happenings/${slug}/${number}`
    }
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    fade: false,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    initialSlide: slideIndex,
    centerMode: false,
    beforeChange: (current, next) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: "992px",
        settings: {
          initialSlide: slideIndex,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }
  return (
    <StyledRow>
      <Pagination>
        <MobilePrevious onClick={() => changeSlide(-1)}>
          <ArrowLeft src={LeftArrowImg} />
        </MobilePrevious>
        {pageNumbers.length > 4 && currentPage !== 1 && (
          <Previous onClick={() => changeSlide(-1)}>
            <ArrowLeft src={LeftArrowImg} />
          </Previous>
        )}
        <StyledSlider {...settings} ref={slideRef}>
          {pageNumbers.map((number, index) => {
            return (
              <div key={index}>
                <PaginationNumber
                  active={currentPage === number}
                  to={getPath(slug, number)}
                >
                  {number < 10 ? `0${number}` : number}
                </PaginationNumber>
              </div>
            )
          })}
        </StyledSlider>
        {pageNumbers.length > 4 && currentPage !== total && (
          <Next onClick={() => changeSlide(1)}>
            <ArrowRight src={RightArrowImg} />
          </Next>
        )}
        <MobileNext onClick={() => changeSlide(1)}>
          <ArrowRight src={RightArrowImg} />
        </MobileNext>
      </Pagination>
    </StyledRow>
  )
}

export default PaginationBlock
