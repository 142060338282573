import React, {useState, useEffect} from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import { Row, Container } from "styled-bootstrap-grid"
import Img from "gatsby-image"
import { Link } from "gatsby"

const MenuBackground = styled.div`
    width: 100%;
    background: #050c15;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    box-sizing: border-box;

`

const ContentContainer = styled(Container)`
    padding: 10px 0 30px 0;
`

const RowStyled = styled(Row)`
    margin-left: 0;
    margin-right: 0;
    padding-left: 35px;
    padding-right: 35px;    
    @media(min-width: 768px){
        padding-left: 0;
        padding-right: 0;    
    }
`

const NavLinkStyled = styled(Link)`
-webkit-tap-highlight-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #9CA8B5;
    ${fonts.gilroyBold};
    text-transform: uppercase;
    position: relative;
    font-size: 12px;
    margin: 0px;
    text-decoration: none;
    letter-spacing: 2.8px;
    padding: 5px;
    :first-of-type {
        padding-left: 0;
    }
    :last-of-type {
        padding-right: 0;
    }
    ${({active}) => active && "color: white"};
     @media (min-width: 768px) {
        font-size: 14px;
        padding: 8px;
     }
    &:hover,
    &:active {
    color: white;
    }
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        -webkit-tap-highlight-color: transparent;
    }
`;
const NavLinkText = styled.div`
    position: relative;
    -webkit-tap-highlight-color: transparent;
    padding-bottom: 10px;
    :after {
        content: '';
        border-bottom: 3px solid transparent;
        display: block;
        bottom: 0;
        position: absolute;
        right: 3px;
        left: 0;
    }
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
        :after {
            content: '';
            border-bottom: 3px solid #9DDCE2;
            display: block;
            position: absolute;
            right: 3px;
            left: 0;
            -webkit-tap-highlight-color: transparent;
        }
    }
`;

const OverlaySplash = styled(Img)`
    width: 100%;
    display: none;
    bottom: -1px !important;

    @media(min-width: 768px){
        display: block;
    }
`;

const OverlaySplashMobile = styled(Img)`
    width: 100%;
    display: block;
    bottom: -1px !important;
    @media(min-width: 768px){
        display: none;
    }
`;

const NavBlock = (props) => {
    const categories = [{link: "/happenings" , category: "All"}]
    props.categories.forEach(({category}) => categories.push({link: `/happenings/${category.toLowerCase()}`, category: category}));
    const [pathname, setPathname] = useState();

    useEffect(() => {
        if (typeof window !== undefined) {
            const path = window.location.pathname;
            setPathname(path)
        }
    }, [])

  return (
    <MenuBackground>
      <ContentContainer>
        <RowStyled>
            {categories.map(({category, link}, index) => (
                <NavLinkStyled to={link} key={index} active={pathname === link}><NavLinkText>{category}</NavLinkText></NavLinkStyled>
            ))}
        </RowStyled>
      </ContentContainer>
      <OverlaySplash fluid={props.splashBrush.childImageSharp.fluid} alt={"Intro Image"}/>
      <OverlaySplashMobile fluid={props.splashBrushMobile.childImageSharp.fluid} alt={"Intro Image"}/>
    </MenuBackground>
  )
}

export default NavBlock;

