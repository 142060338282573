import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { Container } from "styled-bootstrap-grid"
import Title from "./Title"
import OverlayBottom from "../core/OverlayBottom"

const BackgroundStyled = styled.div`
  position: relative;
  height: 537px;
  margin-top: -138px;
  text-align: center;
  @media (min-width: 768px) {
    height: 710px;
  }
`

const BackgroundImageStyled = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-size: cover;
`

const HomeHero = ({ intro }) => {
  const { hero, title, subTitle } = intro;
  return (
    <BackgroundStyled>
      <BackgroundImageStyled fluid={hero.fluid} critical={true} durationFadeIn={100}>
        <OverlayBottom />
        <Container>
          <Title title={title} secondTitle={subTitle}/>
        </Container>
      </BackgroundImageStyled>
    </BackgroundStyled>
  )
}

export default HomeHero
