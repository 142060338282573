import React from "react"
import styled from "styled-components"
import { Row, Col } from "styled-bootstrap-grid"
import Img from "gatsby-image"
import fonts from "../../styles/fonts"
import VerticalLineImg from "../../resources/images/happenings/vertical-line.svg"
import HorizontalLineImg from "../../resources/images/happenings/horizontal-line.svg"
import Button from "../core/Button"
import colors from "../../styles/colors"
import { Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"


const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
`

const NewsRow = styled(Row)`
    margin-left: 0;
    margin-right: 0;
    padding-left: 33px;
    padding-right: 33px;    
    @media(min-width: 768px){
        padding-left: 0;
        padding-right: 0;    
    }
`

const StyledNewsEntry = styled.div`
    padding-top: 39px;
    padding-bottom: 80px;
    @media(min-width: 768px){
        padding-left: ${props => props.reverse ? "0" : "95px"};
        padding-top: 73px;
    }
`

const NewsDate = styled.div`
    font-size: 12px;
    line-height: 14px;
    color: ${colors.navy};
    letter-spacing: 2.8px;
    ${fonts.gilroyBold};
    text-transform: uppercase;
    padding-left: 45px;
    @media(min-width: 768px){
        padding-left: 0;
    }
`

const NewsTitle = styled.div`
    font-size: 20px;
    line-height: 24px;
    color: ${colors.navy};
    letter-spacing: 1.3px;
    text-transform: uppercase;
    ${fonts.swissBlackExtended};
    padding-left: 45px;
    padding-top: 25px;
    position: relative;
    @media(min-width: 768px){
        padding-left: 0;
        padding-top: 20px;
        max-width: 363px;
    }
`

const NewsQuote = styled.div`
    font-size: 15px;
    line-height: 21px;
    color: ${colors.grey};
    letter-spacing: 0;
    ${fonts.gilroyRegular};
    padding-top: 40px;
    margin-bottom: 60px;
    a {
      color: ${colors.grey};
    }
    @media(min-width: 768px){
        padding-top: 50px;
        max-width: 361px;
        margin-bottom: 60px;
    }
`

const ImageContainer = styled.div`
    width: auto;
    height: 100%;
    max-height: 238px;
    overflow: hidden;
    @media(min-width: 768px){
        max-height: 443px;
        margin-bottom: 140px;
    }
`

const NewsImg = styled(Img)`
    height: 100%;
    width: 100%;
    transition: .3s;
    &:hover {
        transform: scale(1.1);
        transition: .3s;
    }
`

const VerticalLine = styled.img`
    position: absolute;
    margin-left: 22.5px;
    top: -12%;
    @media(min-width: 768px){
        display: none;
    }
`

const HorizontalLine = styled.img`
    position: absolute;
    bottom: -20px;
    left: ${props => props.reverse && "40%"};
    right: ${props => !props.reverse && "40%"};
    @media(max-width: 768px){
        display: none;
    }
`

const NewsEntry = (props) => {
  const { date, title, quote, imgSrc, reverse, link } = props

  if (reverse === true) {
    return (
      <NewsRow>
        <ColStyled md={7}>
          <ImageContainer>
            <Link to={`/happenings/${link}`}>
              <NewsImg fluid={imgSrc}/>
            </Link>
          </ImageContainer>
        </ColStyled>
        <ColStyled md={5}>
          <StyledNewsEntry>
            <NewsDate>{date}</NewsDate>
            <NewsTitle>
              {title}
              <HorizontalLine src={HorizontalLineImg}/>
            </NewsTitle>
            <VerticalLine src={VerticalLineImg}/>
            <NewsQuote>{documentToReactComponents(quote.json)}</NewsQuote>
            <Button text={"Read More"} link={`/happenings/${link}`} dark/>
          </StyledNewsEntry>
        </ColStyled>
      </NewsRow>
    )
  }
  return (
    <NewsRow>
      <ColStyled md={7} smOrder={1} mdOrder={2} lgOrder={2}>
        <ImageContainer>
          <Link to={`/happenings/${link}`}>
            <NewsImg fluid={imgSrc}/>
          </Link>
        </ImageContainer>
      </ColStyled>
      <ColStyled md={5} smOrder={2} mdOrder={1} lgOrder={1}>
        <StyledNewsEntry reverse>
          <NewsDate>{date}</NewsDate>
          <NewsTitle>
            {title}
            <HorizontalLine src={HorizontalLineImg} reverse/>
          </NewsTitle>
          <VerticalLine src={VerticalLineImg}/>
          <NewsQuote>{documentToReactComponents(quote.json)}</NewsQuote>
          <Button text={"Read More"} link={`/happenings/${link}`} dark/>
        </StyledNewsEntry>
      </ColStyled>
    </NewsRow>
  )
}

export default NewsEntry
